import { useEffect, useState } from "react";
import useInterval from "@use-it/interval";
import logo from "./BBOARD.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BearBoardMessage } from "./interfaces";
import { BillBoard } from "./components/BillBoard";
import { Calculator } from "./components/Calculator";
import {
  BEARBOARD_S3_LATEST_DATA_URL,
  BEARBOARD_POLLING_INTERVAL,
  BEARBOARD_S3_HISTORY_URL,
} from "./utils/constants";
import { calculateDuration, stringifyDuration } from "./utils/utils";

function App() {
  const [currentMessage, setCurrentMessage] = useState(
    {} as BearBoardMessage | null
  );
  const [latestMessage, setLatestMessage] = useState(
    {} as BearBoardMessage | null
  );
  const [queueLength, setQueueLength] = useState(0);
  const [lastUpdatedHeight, setLastUpdatedHeight] = useState(-1);
  const [nextAvailableBlock, setNextAvailableBlock] = useState(-1);
  const [currentBlock, setCurrentBlock] = useState(-1);
  const [history, setHistory] = useState([] as Array<BearBoardMessage>);
  const [historyPosition, setHistoryPosition] = useState(-1);

  const fetchLatestBlock = async () => {
    fetch("https://xchscan.com/api/blocks?limit=1", {
      method: "get",
      cache: "no-store",
    })
      .then((response) => response.json())
      .then((data) => {
        const latestBlock = data.blocks[0].height;
        setCurrentBlock(parseInt(latestBlock));
      })
      .catch((error) => {
        console.error("Failed to get latest block data from XCHscan: ", error);
      });
  };

  const fetchLatestData = async () => {
    fetch(BEARBOARD_S3_LATEST_DATA_URL, {
      method: "get",
      cache: "no-store",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          JSON.stringify(data.current_message) !== JSON.stringify(latestMessage)
        ) {
          setLatestMessage(data.current_message);
          if (historyPosition === -1) {
            setCurrentMessage(data.current_message);
          }
          fetchHistory();
        }

        setQueueLength(data.queue.remaining);
        setLastUpdatedHeight(data.last_updated_height);
        setNextAvailableBlock(data.next_available_block);
      });
  };

  const fetchHistory = async () => {
    fetch(BEARBOARD_S3_HISTORY_URL, {
      method: "get",
      cache: "no-store",
    })
      .then((response) => response.json())
      .then((history) => {
        setHistory(history);
      });
  };

  useEffect(() => {
    fetchLatestBlock();
    fetchLatestData();
    fetchHistory();
  }, []); // eslint-disable-line

  useInterval(() => {
    fetchLatestBlock();
    fetchLatestData();
  }, BEARBOARD_POLLING_INTERVAL);

  const goToHistory = (direction: string) => {
    if (history.length === 0) {
      return;
    }
    if (direction === "forward") {
      if (historyPosition === -1) {
        return;
      }

      if (historyPosition === history.length - 1) {
        setHistoryPosition(-1);
        setCurrentMessage(latestMessage);
        return;
      }

      const position = historyPosition + 1;
      setCurrentMessage(history[position]);
      setHistoryPosition(position);
    } else if (direction === "backwards" && historyPosition !== 0) {
      let position =
        historyPosition === -1 ? history.length - 1 : historyPosition - 1;
      setCurrentMessage(history[position]);
      setHistoryPosition(position);
    } else {
      return;
    }
  };

  return (
    <>
      <div className="container-fluid mt-4 px-0 mb-4">
        <div className="container-md">
          <div className="row h-100 align-items-stretch justify-content-center text-center">
            <div className="col-12 col-lg-10 col-xl-8 text-light">
              <div className="col-12 mx-auto mb-5 no-select">
                <img src={logo} className="img-fluid bboard-logo" alt="logo" />
              </div>
              <BillBoard
                message={currentMessage}
                goToHistory={goToHistory}
                historyPosition={historyPosition}
                currentBlock={currentBlock}
                lastUpdatedHeight={lastUpdatedHeight}
              />
              <p className="text-light fs-5 fw-light">
                Rent the <span className="fw-normal text-honey">BEARBOARD</span>{" "}
                and{" "}
                <span className="fw-normal text-honey">leave your message</span>{" "}
                on the blockchain.
                {currentBlock >= 0 &&
                nextAvailableBlock >= 0 &&
                currentBlock < nextAvailableBlock ? (
                  <>
                    <br/>
                    The first <span className="text-honey">available block</span> is{" "}
                    <span>
                      {nextAvailableBlock}
                    </span>{" "}
                    (~
                    {stringifyDuration(
                      calculateDuration(nextAvailableBlock - currentBlock),
                      1
                    )}{" "}
                    from now).
                  </>
                ) : (
                  ""
                )}
              </p>
              <Calculator />
              <p className="text-light fs-5 fw-light">
                {queueLength > 0 ? (
                  <>
                    There {queueLength > 1 ? "are" : "is"}
                    <span className="fw-bold text-honey fs-4">
                      {" "}
                      {queueLength}
                    </span>{" "}
                    message{queueLength > 1 ? "s" : ""} in the queue.
                    <br />
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="container-fluid mb-4 mt-auto">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="d-flex col-12 justify-content-center no-select">
              <p className="text-white text-center m-0">
                Copyright © 2022 - 2024
                <br />
                <a
                  href="https://hairybearsocial.club"
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hairy Bear Social Club
                </a>
                <br />
                <span className="text-white">Powered by XCHscan.com APIs.</span>
              </p>
            </div>
            <div className="d-flex col-12 justify-content-center">
              <a
                href="https://twitter.com/hairybearsc"
                className="btn btn-link text-white btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
              <a
                href="https://discord.gg/nx4mXMs3xw"
                className="btn btn-link text-white btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-discord"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
