import { useEffect, useRef, useState } from "react";

import {
  BEARBOARD_WALLET_ADDRESS,
  DEFAULT_BLOCK_DURATION,
  MAX_BLOCK_DURATION,
} from "../utils/constants";
import {
  calculateCost,
  calculateDuration,
  formatPlural,
  stringifyDuration,
} from "../utils/utils";

export const Calculator = () => {
  let blocksToLastSpanValue = DEFAULT_BLOCK_DURATION;
  const [blocksToLast, setBlocksToLast] = useState(DEFAULT_BLOCK_DURATION);
  const blockInput = useRef(null);


  const setInitialFocus = () => {
    // @ts-ignore
    blockInput.current.focus();
    const range = document.createRange()
    const selection = window.getSelection()
    // @ts-ignore
    range.setStart(blockInput.current, 1)
    range.collapse(true)
    selection?.removeAllRanges()
    selection?.addRange(range)

  }


  useEffect(() => {
    setInitialFocus();
  }, []);

  const onBlockNumberChange = (blocks: string | null) => {
    let b: number;
    if (blocks === null || blocks === undefined || parseInt(blocks) < 0 || blocks === "") {
      b = 0;
    } else {
      b = parseInt(blocks);
      if (b > MAX_BLOCK_DURATION) {
        b = DEFAULT_BLOCK_DURATION;
        // @ts-ignore
        blockInput.current.textContent = b;
        setInitialFocus();
      }
    }
    if (b !== blocksToLast) {
      // @ts-ignore
      blockInput.current.val = b;
      blocksToLastSpanValue = b;
      setBlocksToLast(b);
    }
  };

  return (
    <p className="text-light fs-5 fw-light">
      I want my message to last
      <span
        className="px-2 mx-2 fw-bold text-honey fs-4 inline-input w-auto d-inline-block br-disable input-honey"
        contentEditable="true"
        suppressContentEditableWarning={true}
        onInput={(e) =>
          onBlockNumberChange(e.currentTarget.textContent)
        }
        ref={blockInput}
      >{blocksToLastSpanValue}</span>
      {formatPlural(blocksToLast, "block")}
      {blocksToLast > 0
        ? ` (~${stringifyDuration(calculateDuration(blocksToLast))}).`
        : "."}
      <br />
      Please send{" "}
      <span className="fw-bold text-honey fs-4">
        {calculateCost(blocksToLast)}
      </span>{" "}
      <a
        href="https://honey.hairybearsocial.club"
        className="text-white"
        target="_blank"
        rel="noreferrer"
      >
        HONEY
      </a>{" "}
      with{" "}
      <span className="fw-normal text-honey">
        your message as a custom memo
      </span>{" "}
      to this address: <br />
      <span className="badge rounded-pill bg-light text-dark text-break mt-2">
        {BEARBOARD_WALLET_ADDRESS}
      </span>
    </p>
  );
};
