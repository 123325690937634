export const BEARBOARD_S3_BUCKET_NAME = `hbsc-bearboard`;
export const BEARBOARD_S3_LATEST_DATA_URL = `https://${BEARBOARD_S3_BUCKET_NAME}.s3.amazonaws.com/public/latest_data.json`;
export const BEARBOARD_S3_HISTORY_URL = `https://${BEARBOARD_S3_BUCKET_NAME}.s3.amazonaws.com/public/history.json`;

export const BEARBOARD_WALLET_ADDRESS = `xch1eqa9kslx33qxclqxdlchz5m2teg3hexkluwnsgmy0feecwrsyd8s8szz8p`;
export const BEARBOARD_BLOCK_COST = 1; // HONEY per block
export const BEARBOARD_POLLING_INTERVAL = 1000 * 30; // 30 seconds
export const BLOCK_TIME_SECONDS = 18.75;
export const DEFAULT_BLOCK_DURATION = 100;
export const MAX_BLOCK_DURATION = 10000000;

export const BEAR_EMOJIS = ["🐻", "🐻‍❄️", "🧸", "🍯"]
export const EMPTY_BLOCK_MESSAGES = ["This space for you", "Make this yours", "Leave your trace here", "Paw-vertise here 🐾"]
export const EMPTY_MESSAGE_MESSAGES = ["Uh oh, nothing to see here :(", "Wow, such empty"]

export const BEARBOARD_HEIGHT_PX = 400;
export const BEARBOARD_URL_LENGTH_LIMIT = 25;