import { FunctionComponent, useEffect, useMemo } from "react";

// @ts-ignore
import textBalancer from "../utils/nyt-text-balancer";

import Linkify from "linkify-react";
import "linkify-plugin-mention";
import "linkify-plugin-hashtag";
// @ts-ignore
import useKeypress from "react-use-keypress";

import {
  BEARBOARD_HEIGHT_PX,
  BEARBOARD_URL_LENGTH_LIMIT,
  BEAR_EMOJIS,
  EMPTY_BLOCK_MESSAGES,
  EMPTY_MESSAGE_MESSAGES,
} from "../utils/constants";

import { BearBoardMessage } from "../interfaces";
import { randomFromArray } from "../utils/utils";

interface Props {
  message: BearBoardMessage | null;
  historyPosition: number;
  currentBlock: number;
  lastUpdatedHeight: number;
  goToHistory: (direction: string) => void;
}

const preprocessMessage = (message: string) => {
  // remove 't:' before twitter handle
  message = message.replace(/\bt:(@[a-zA-Z0-9_]+)\b/, "$1");

  return message;
};

export const BillBoard: FunctionComponent<Props> = ({
  message,
  historyPosition,
  currentBlock,
  lastUpdatedHeight,
  goToHistory,
}: Props) => {
  const randomEmoji = useMemo(() => randomFromArray(BEAR_EMOJIS), []);
  const randomEmptyBlockMessage = useMemo(
    () => randomFromArray(EMPTY_BLOCK_MESSAGES),
    []
  );
  const randomNoMessageMessage = useMemo(
    () => randomFromArray(EMPTY_MESSAGE_MESSAGES),
    []
  );

  useEffect(() => {
    textBalancer.balanceText(null, BEARBOARD_HEIGHT_PX);
  }, [message]);

  useKeypress(["ArrowLeft", "ArrowRight"], (event: any) => {
    if (document.activeElement?.className.includes("text-honey")) {
      return
    }

    if (event.key === "ArrowLeft") {
      goToHistory("backwards");
    } else if (event.key === "ArrowRight") {
      goToHistory("forward");
    }
  });

  const buildBlockMessage = () => {
    if (message != null && message.valid_from) {
      return message.valid_from === message.valid_until
        ? `block ${message.valid_from}`
        : `blocks ${message.valid_from} to ${message.valid_until}`;
    }

    if (currentBlock > -1) {
      return `block ${currentBlock}`;
    }

    // we have no current block info, fallback to latest updated height
    if (lastUpdatedHeight > -1) {
      return `since block ${lastUpdatedHeight}`;
    }

    return "loading...";
  };

  return (
    <div className="position-relative mb-5-plus mx-auto">
      <div className="display-4 fw-lighter bboard-billboard text-wrap">
        <div className="fader fader-up"></div>
        <button
          type="button"
          className="history-arrow history-arrow-l btn btn-link text-light"
          onClick={(e) => goToHistory("backwards")}
          hidden={historyPosition === 0}
          aria-label="Previous message"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
          </svg>
        </button>
        <div className="scrollable p-5 break-all balance-text">
          {message === null || message.message === undefined ? (
            <span className="text-secondary">
              <span className="display-1">{randomEmoji}</span>
              <br />
              <span className="fst-italic">{randomEmptyBlockMessage}</span>
            </span>
          ) : message.message === "" ? (
            <span className="text-secondary">
              <span className="display-1">🤷‍♀️</span>
              <br />
              <span className="fst-italic">{randomNoMessageMessage}</span>
            </span>
          ) : (
            <Linkify
              options={{
                className: "text-white",
                target: "_blank",
                rel: "noreferrer",
                format: (value: string, type: string) => {
                  if (type === "url") {
                    value = value.replace(/^https?:\/\//, "")
                    if (value.length > BEARBOARD_URL_LENGTH_LIMIT + 3) {
                      value = value.slice(0, BEARBOARD_URL_LENGTH_LIMIT) + "..."
                    }
                  }
                  return value;
                },
                formatHref: {
                  mention: (href: string) => "https://twitter.com/" + href,
                  hashtag: (href: string) =>
                    "https://twitter.com/hashtag/" + href.slice(1),
                },
              }}
            >
              {preprocessMessage(message.message)}
            </Linkify>
          )}
        </div>
        <button
          type="button"
          className="history-arrow history-arrow-r btn btn-link text-light"
          onClick={(e) => goToHistory("forward")}
          hidden={historyPosition === -1}
          aria-label="Next message"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          </svg>
        </button>
        <div className="fader fader-down"></div>
        <div className="block-info fs-6 py-1 px-2 fst-italic fw-light">
          {buildBlockMessage()}
        </div>
      </div>
    </div>
  );
};
