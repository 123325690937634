import moment from "moment"

import { BEARBOARD_BLOCK_COST, BLOCK_TIME_SECONDS } from "./constants";

export const calculateCost = (blocks: number) => {
  if (isNaN(blocks)) {
    return 0;
  }
  return BEARBOARD_BLOCK_COST * blocks;
};

export const calculateDuration = (blocks: number) => {
  if (isNaN(blocks)) {
    return moment.duration(0);
  }
  return moment.duration(BLOCK_TIME_SECONDS * blocks, "seconds");
};

export const formatPlural = (n: number, prefix: string) => n === 1 ? prefix : `${prefix}s`


export const stringifyDuration = (d: moment.Duration, parts: number = 3) => {
  let result = Array<string>();
  const keys = ["years", "months", "days", "hours", "minutes", "seconds"]
  for (const k of keys) {
    // @ts-ignore
    const v = d.get(k);
    if (v > 0) {
      result.push(`${v} ${formatPlural(v, k.slice(0, -1))}`);
      // result.push(`${v}${k[0]}`);
    }
  }
  return result.slice(0, parts).join(", ");
}

export const randomFromArray = (a: any[]) => a[Math.floor(Math.random() * a.length)];